import React, { useState, useEffect } from "react";

const ProgressBar = () => {
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrolled = (winScroll / height) * 100;
      setScroll(scrolled);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      style={{
        width: "3px",
        height: `${scroll}%`,
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 999999,
        background: "#FF7D50",
        transformOrigin: "50% 0",
        transitionDuration: "200ms",
        transitionTimingFunction: "ese-out",
      }}
    ></div>
  );
};
export default ProgressBar;
