import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { publicRoutes } from "./routers/router";
import Layout from "./layout";

function App() {
  return (
    <Router>
      <Suspense>
        <Routes>
          <Route element={<Layout />}>
            {publicRoutes.map((route, index) => {
              const Page = route.component;
              return (
                <Route key={index}>
                  <Route path={route.path} element={<Page />} />
                </Route>
              );
            })}
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
