import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { addDocument } from "../../firebase/service";

const listLinkSocial = [
  {
    icon: "/assets/icons/footer/icon1.svg",
    link: "https://www.linkedin.com/company/77129839",
  },
  {
    icon: "/assets/icons/footer/icon2.svg",
    link: "https://twitter.com/GroundPlay3",
  },
  {
    icon: "/assets/icons/footer/icon3.svg",
    link: "https://www.facebook.com/profile.php?id=100076419272793",
  },
  {
    icon: "/assets/icons/footer/icon5.svg",
    link: "https://www.youtube.com/channel/UCYDsbyvaU4_pjdrJoHyLykw",
  },
];

const Footer = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const [errEmail, setErrEmail] = useState("");

  const listLink = [
    { title: t("home.menu.Home"), link: "/#" },
    { title: t("home.menu.About"), link: "/#about" },
    { title: t("home.menu.Service"), link: "/#service" },
    { title: t("home.menu.Careers"), link: "/#careers" },
    { title: t("home.menu.Contact"), link: "/#contact" },
  ];

  const handleSubmitEmail = async () => {
    if (errEmail.length > 0 || value.length === 0) return;
    try {
      await addDocument("email", { email: value });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <StyledFooter>
      <div className="container">
        <div className="row mb-4 align-items-center justify-content-bettween">
          <div className="col-12 col-sm-5 ">
            <Link to="/">
              <img src="/assets/icons/logoft.svg" alt="logo" />
            </Link>
          </div>
          <div className="col-12 col-sm-7 list_link">
            {listLink.map((item, index) => {
              return (
                <a className="link_hover" key={index} href={item.link}>
                  {item.title}
                </a>
              );
            })}
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            {/* {listLinkSocial.map((item, index) => {
              return (
                <a
                  className="social_icon"
                  href={item.link}
                  key={index}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={item.icon} alt="icon" />
                </a>
              );
            })} */}
            <div className="mb-3">
              <div className="title_footer_1 mb-2">KOREA</div>
              <div className="content_title_1 d-flex gap-2 align-items-start">
                <img src="/assets/icons/1.svg" />
                Gangnam Building | 211-20 Nonhyeon-dong, Gangnam-gu, Seoul, South Korea
              </div>
            </div>
            <div className="mb-3">
              <div className="title_footer_1 mb-2">VIETNAM</div>
              <div className="content_title_1  d-flex gap-2 align-items-start">
                <img src="/assets/icons/1.svg" />
                14th Floor, HM Town Building, 412 Nguyen Thi Minh Khai, District 3, Ho Chi Minh City, Vietnam
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 ">
            <div className="d-flex align-items-center justify-content-end custom_input">
              <span>Subcribe for newsletter</span>
              <div className="group_email">
                <input
                  type="email"
                  placeholder="Email"
                  onChange={(e) => {
                    const regexEmail =
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    if (!regexEmail.test(e.target.value)) {
                      setErrEmail("Email is not valid!");
                    } else {
                      setValue(e.target.value);
                      setErrEmail(""); 
                    }
                  }}
                />
                <p className="text-err text-danger">{errEmail}</p>
                <img
                  className="icon_email"
                  src="/assets/icons/footer/email.svg"
                  alt="email"
                />
                <img
                  className="icon_send"
                  src="/assets/icons/footer/send.svg"
                  alt="send"
                  onClick={handleSubmitEmail}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center justify-content-md-end mt-3">
              <div className="list_socile">
                {listLinkSocial.map((item, index) => {
                  return (
                    <a
                      className="social_icon"
                      href={item.link}
                      key={index}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={item.icon} alt="icon" />
                    </a>
                  );
                })}
              </div>
            </div>

          </div>

        </div>
        <hr style={{ color: "#ccc", height: "2px" }} />
        <div className="row footer_bot">
          <div className="col-12 col-sm-6">
            <p>Copyright © 2023 Playground. All Rights Reserved.</p>
          </div>
          <div className="col-12 col-sm-6 d-flex align-items-center justify-content-end">
            <p>Welcome to the hottest Playground in town!</p>
          </div>
        </div>
      </div>
    </StyledFooter>
  );
};

export default Footer;

const StyledFooter = styled.div`
  background-color: #303030;
  padding: 50px 0;
  .list_link {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 30px;
    a {
      text-decoration: none;
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
      color: #00896f;
      transition: all 0.25s ease-in;
    }
    .link_hover:hover {
      color: #ff7d50;
    }
  }
  .list_socile {
    display: flex;
    gap: 30px;
    .social_icon:hover {
      transition: all 0.5s ease-in-out;
      transform: scale(1.3);
    }
  }
  .custom_input {
    gap: 30px;
    span {
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      color: #b3b3b3;
    }
    .group_email {
      position: relative;
      .icon_email {
        position: absolute;
        top: 50%;
        left: 5%;
        transform: translateY(-50%);
      }
      .icon_send {
        position: absolute;
        top: 50%;
        right: 5%;
        z-index: 999;
        transform: translateY(-50%);
        cursor: pointer;
      }
      .text-err {
        position: absolute;
        bottom: -80%;
        left: 0%;
        font-size: 14px;
      }
      input {
        border-radius: 30px;
        padding: 10px 35px;
        background-color: inherit;
        border: 1px solid #ccc;
        outline: none;
        color: white;
      }
    }
  }
  .footer_bot {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #b3b3b3;
  }
  @media (max-width: 820px) {
    padding: 20px 0;
    .list_link {
      gap: 10px;
      justify-content: center;
      flex-wrap: wrap;
      a {
        font-size: 13px;
      }
    }
    .list_socile {
      margin-top: 0;
      justify-content: center;
      flex-wrap: wrap;
    }
    .custom_input {
      flex-direction: column;
      gap: 10px;
      margin-top: 20px;
      .input {
        padding: 10px 30px;
      }
    }
    .footer_bot {
      text-align: center;
      .justify-content-end {
        display: none !important;
      }
    }
  }
`;
