import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import Multilanguage from "../Mutilanguage";

const Menu = ({ showMenu, setShowMenu }) => {
  let location = useLocation();

  const { t } = useTranslation();
  const listMenu = [
    {
      title: t("home.menu.Home"),
      link: "/#",
    },
    {
      title: t("home.menu.About"),
      link: "/#about",
    },
    {
      title: t("home.menu.Service"),
      link: "/#service",
    },
    {
      title: t("home.menu.Careers"),
      link: "/#careers",
    },
    {
      title: t("home.menu.Contact"),
      link: "/#contact",
    },
  ];

  useEffect(() => {
    const listLink = document.getElementsByClassName("menu_hover");
    [...listLink]?.map((item) => {
      location.hash === item?.hash
        ? item.classList.add("active")
        : item.classList.remove("active");
    });
  }, [location.hash]);

  return (
    <WrapMenu showMenu={showMenu}>
      <div className="container">
        {listMenu.map((item, index) => {
          return (
            <a
              className="menu_hover"
              key={index}
              href={item.link}
              onClick={() => {
                setShowMenu(false);
              }}
            >
              {item.title}
            </a>
          );
        })}
        <Multilanguage setShowMenu={setShowMenu} />
      </div>
    </WrapMenu>
  );
};
export default Menu;

const animaLink = keyframes`
  from{
    transform: translateX(0);
  }
  to{
    transform: translateX(40px);
  }
`;

const WrapMenu = styled.div`
  background: #000000ba;
  position: fixed;
  top: ${({ showMenu }) => (showMenu ? "0" : "-1000px")};
  opacity: ${({ showMenu }) => (showMenu ? "1" : "0")};
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in;
  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .menu_hover:hover {
      color: #ff7d50;
      transition: all 0.25 ease-in-out;
      animation: ${animaLink} 0.5s infinite alternate;
    }
  }
  a {
    width: 410px;
    color: white;
    text-decoration: none;
    font-weight: 700;
    font-size: 60px;
    line-height: 106px;
    display: flex;
    align-items: center;
    letter-spacing: 3px;
  }
  .active {
    font-weight: 700;
    letter-spacing: 3px;
    color: #00a19a;
  }

  @media (max-width: 1024px) {
    a {
      text-align: center;
      font-size: 60px;
      line-height: 80px;
    }
  }

  @media (max-width: 600px) {
    a {
      text-align: center;
      font-size: 30px;
      line-height: 60px;
      width: 225px;
    }
  }
`;
