import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useWindowSize from "../../hooks/useWindownSize";
import { GrClose } from "react-icons/gr";

const Header = ({ showMenu, setShowMenu }) => {
  const burgAnimation = () => {
    var path1 = document.querySelector(".path1");
    var path2 = document.querySelector(".path2");
    var mline = document.querySelector(".mline");
    if (showMenu) {
      path1?.classList?.add("cross");
      path2?.classList?.add("cross");
      mline?.classList?.add("hide");
    } else {
      path1?.classList?.remove("cross");
      path2?.classList?.remove("cross");
      mline?.classList?.remove("hide");
    }
  };

  useEffect(() => {
    burgAnimation();
  }, [showMenu]);

  const width = useWindowSize();

  return (
    <WrapHeader>
      <div className="container-fluid">
        <a href="/">
          <div className="logo">
            <img src="/assets/icons/logo.svg" alt="logo" />
          </div>
        </a>
        {width > 820 ? (
          <IconMenu
            onClick={() => {
              setShowMenu(!showMenu);
            }}
          >
            <svg
              className="svgburg"
              width="95"
              height="93"
              viewBox="0 0 45 43"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="path1"
                d="M1.5 7L27.5 33C27.5 33 30.5 36 36.5 40.5C42.5 45 48 33.5 41.5 33C35 32.5 2 33 2 33"
                stroke="black"
                strokeWidth="2"
              />

              <path
                className="path2"
                d="M2 33L28 7C28 7 33.5 1 37 1C40.5 1 43 6.20692 40 7C37 7.79308 1 7 1 7"
                stroke="black"
                strokeWidth="2"
              />

              <path
                className="mline"
                d="M1.5 20H28.5"
                stroke="black"
                strokeWidth="2"
              />
            </svg>
          </IconMenu>
        ) : (
          <>
            {showMenu ? (
              <div className="icon_close">
                <GrClose
                  onClick={() => {
                    setShowMenu(!showMenu);
                  }}
                />
              </div>
            ) : (
              <img
                onClick={() => {
                  setShowMenu(!showMenu);
                }}
                src="/assets/icons/menu.svg"
                alt="logo"
              />
            )}
          </>
        )}
      </div>
    </WrapHeader>
  );
};

export default Header;

const WrapHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: transparent;
  z-index: 9999;
  padding: 20px;

  .container-fluid {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .icon_close {
    svg {
      font-size: 35px;
      path {
        stroke-width: 4px;
        stroke: #00896f;
      }
    }
  }
  @media (max-width: 600px) {
    height: 50px;
    .container-fluid {
      height: 50px;
      padding: 0;
      margin-top: -12px;
      .logo {
        img {
          width: 50px;
          height: 90px;
        }
      }
    }
  }
`;

const IconMenu = styled.div`
  cursor: pointer;
  .path1,
  .path2 {
    stroke-dasharray: 104;
    stroke-dashoffset: -71;
    transition: 1s all ease;
    stroke-width: 4px;
    stroke: #00896f;
  }

  .cross {
    stroke-dashoffset: 68;
  }

  .mline {
    stroke-dasharray: 41;
    stroke-dashoffset: -82;
    transition: 1s all ease;
    stroke: #00896f;
  }

  .hide {
    stroke-dasharray: 43;
    stroke-dashoffset: 130;
  }

  .svgburg {
    width: 50px;
    font-weight: bold;
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    content: unset;
  }
`;
