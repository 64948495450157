import React from "react";
import styled from "styled-components";

const Loading = () => {
  return (
    <StyledLoading>
      <img  src="/assets/icons/loading.gif" style={{width:'200px'}}/>
    </StyledLoading>
  );
};

export default Loading;

const StyledLoading = styled.div`
  /* Loading */
  position: relative;
  background-color: white;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  
`;
