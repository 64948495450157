import React from "react";

const About = React.lazy(() => import("../pages/About"));
const ProjectsExpert = React.lazy(() => import("../pages/ProjectsExpert"));
const ProjectMeta = React.lazy(() => import("../pages/ProjectMeta"));
const Contact = React.lazy(() => import("../pages/Contact"));
const Service = React.lazy(() => import("../pages/Services"));
const Home = React.lazy(() => import("../pages/Home"));
const Terms = React.lazy(() => import("../pages/TermsAndConditions"));
const Teams = React.lazy(() => import("../pages/Team"));
const Careers = React.lazy(() => import("../pages/Careers"));
const Blog = React.lazy(() => import("../pages/Blog"));
const NotFound = React.lazy(() => import("../pages/NotFound"));

export const publicRoutes = [
  { path: "/about", component: About },
  { path: "/careers", component: Careers },
  { path: "/team", component: Teams },
  { path: "/blog", component: Blog },
  { path: "/contact", component: Contact },
  { path: "/services", component: Service },
  { path: "/terms", component: Terms },
  { path: "/projects/expert", component: ProjectsExpert },
  { path: "/projects/taekwon", component: ProjectMeta },
  { path: "/home", component: Home },
  { path: "/", component: Home },
  { path: "*", component: NotFound },
];
