import React, { useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import Loading from "../components/common/Loading";
import Menu from "../components/common/Menu";
import Header from "./Header";
import ScrollAnimate from "../components/common/ScrollAnimate";
import Footer from "./Footer";
import useWindowSize from "../hooks/useWindownSize";
import ProgressBar from "../components/common/ProgressBar";

const listSocial = [
  {
    icon: "/assets/icons/social/face.svg",
    link: "https://www.facebook.com/profile.php?id=100076419272793",
  },
  {
    icon: "/assets/icons/social/tt.svg",
    link: "https://twitter.com/GroundPlay3",
  },
  {
    icon: "/assets/icons/social/linkin.jpg",
    link: "https://www.linkedin.com/company/playgroundx-site/",
  },
];

const Layout = () => {
  const [loading, setLoading] = useState(true);
  const [showMenu, setShowMenu] = useState(false);
  const [height, setHeight] = useState(0);
  const refHeight = useRef(null);

  useEffect(() => {
    if (refHeight && refHeight.current) {
      setHeight(refHeight.current.clientHeight);
    }
  });

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  const width = useWindowSize();

  return (
    <WrapLayout>
      <ProgressBar />
      {width > 600 && <div className="cursor_animation"></div>}
      <Menu showMenu={showMenu} setShowMenu={setShowMenu} />
      {loading ? (
        <Loading />
      ) : (
        <>
          <Header showMenu={showMenu} setShowMenu={setShowMenu} />
          <div ref={refHeight} className="content">
            <Outlet />
          </div>
          <Footer />
        </>
      )}
      {width > 600 && (
        <>
          <div className="text-left">@ 2021 - 2023</div>
          <div className="text-right">
            <a href="#contact">
              Get in touch
              <img className="icon-cmt" src="/assets/icons/cmt.svg" />
            </a>
          </div>

          <div className="social-right">
            {listSocial.map((item, index) => {
              return (
                <a href={item.link} key={index} target="_blank">
                  <img width={index === 2 && 20} src={item.icon} alt="icon" />
                </a>
              );
            })}
          </div>
          <ScrollAnimate heightPage={height} />
        </>
      )}
    </WrapLayout>
  );
};

export default Layout;

const WrapLayout = styled.div`
  position: relative;
  .text-left {
    position: fixed;
    bottom: 10%;
    left: -9px;
    color: black;
    z-index: 1001;
    transform: rotate(90deg);
    font-weight: 400;
    font-size: 7px;
    line-height: 8px;
    letter-spacing: 3px;
  }
  .text-right {
    position: fixed;
    bottom: 50%;
    right: -20px;
    z-index: 1001;
    transform: rotate(90deg);
    font-weight: 400;
    font-size: 9px;
    line-height: 10px;
    letter-spacing: 3px;
    color: black;

    a {
      color: black;
      display: flex;
      gap: 10px;
      align-items: center;
    }
    .icon-cmt {
      transform: rotate(-90deg);
    }
  }
  .social-right {
    display: flex;
    position: fixed;
    flex-direction: column;
    bottom: 10%;
    right: 32px;
    z-index: 1001;
    font-weight: 400;
    font-size: 9px;
    line-height: 10px;
    letter-spacing: 3px;
    color: black;
    gap: 30px;
  }
`;
