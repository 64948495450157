import firebase, { db } from "./config";
import Swal from "sweetalert2";

export const addDocument = (collection, data) => {
  const query = db.collection(collection);
  query
    .add({
      ...data,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
    .then(function (docRef) {
      Swal.fire({
        title: "Submit success",
        text: "Thanks for your contribution!",
        icon: "success",
        confirmButtonText: "Ok",
      });
    })
    .catch(function (error) {
      Swal.fire({
        title: "Submit error",
        text: "Sorry server error!",
        icon: "error",
        confirmButtonText: "Ok",
      });
    });
};
