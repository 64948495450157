import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const arrLang = [
  { icon: "/assets/icons/flag/en.svg", value: "en", title: "Eng" },
  { icon: "/assets/icons/flag/kr.svg", value: "ko", title: "Kor" },
  { icon: "/assets/icons/flag/vi.svg", value: "vi", title: "Vie" },
];

const Multilanguage = ({ setShowMenu }) => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState({
    icon: "/assets/icons/flag/en.svg",
    value: "en",
    title: "Eng",
  });

  const handleChangeLanguage = (lang) => {
    const lag = arrLang.find((item) => item.value === lang);
    setLang(lag);
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    if (localStorage.getItem("i18nextLng")) {
      handleChangeLanguage(localStorage.getItem("i18nextLng").substring(0, 2));
    } else {
      setLang({ icon: "/assets/icons/flag/en.svg", value: "en", title: "Eng" });
    }
  }, []);

  return (
    <WrapStyled>
      {arrLang.map((item, index) => {
        return (
          <div
            className={`icon ${
              lang?.value === item?.value ? "active_icon" : ""
            }`}
            key={index}
            onClick={async () => {
              await handleChangeLanguage(item.value);
              setShowMenu(false);
            }}
          >
            <img src={item.icon} alt={item.value} />
            <span>{item.title}</span>
          </div>
        );
      })}
    </WrapStyled>
  );
};

export default Multilanguage;

const WrapStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 410px;

  .icon {
    cursor: pointer;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    height: 40px;
    width: 108px;
    background: #c8c8c8;
    border-radius: 50px;
    font-weight: 500;
    font-size: 24px;
    color: #9c9c9c;
  }
  .active_icon {
    background: #00a19a;
    color: white;
    font-weight: 500;
    font-size: 24px;
  }
  @media (max-width: 600px) {
    margin-top: 20px;
    width: auto;
    .icon {
      width: 90px;
      height: 40px;
      font-size: 20px;
    }
  }
`;
