import React from "react";
import styled, { keyframes } from "styled-components";
import { useScrollYPosition } from "react-use-scroll-position";

const ScrollAnimate = ({ heightPage }) => {
  const scrollY = useScrollYPosition();
  return (
    <StyledScroll>
      {scrollY < heightPage - 1000 ? (
        <>
          <p className="text-scroll">Scroll down</p>
          <img className="img_animate" src="/assets/icons/scroll.svg" />
        </>
      ) : (
        <>
          <img
            className="img_animate_up"
            style={{ transform: "rotate(180deg)" }}
            src="/assets/icons/scroll.svg"
            alt="img_animate_up"
          />
          <p className="text-scroll">Scroll up</p>
        </>
      )}
    </StyledScroll>
  );
};

export default ScrollAnimate;

const downAnimation = keyframes`
    0% { 
        transform: translateY(0);
        opacity :1 ;
    }
    100% { transform: translateY(-20px);
        opacity :0 ;
    }
`;
const upAnimation = keyframes`
    0% { 
        transform: translateY(-20px) rotate(180deg); 
        opacity :1;
    }
    100% { transform: translateY(0) rotate(180deg); 
    opacity :0;
    }
`;

const animationText = keyframes`
 0% { 
        opacity :1;
    }
    100% { 
    opacity :0;
    }
`;

const StyledScroll = styled.div`
  pointer-events: none;
  position: fixed;
  z-index: 9;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 100px;
  .img_animate {
    animation: ${downAnimation} 1s infinite alternate;
  }
  .img_animate_up {
    animation: ${upAnimation} 1s infinite alternate;
  }
  .text-scroll {
    font-weight: 700;
    font-size: 10px;
    line-height: 10px;
    display: flex;
    align-items: center;
    letter-spacing: 9px;
    color: rgba(0, 0, 0, 0.5);
    transform: rotate(90deg);
    animation: ${animationText} 1s infinite alternate;
  }
`;
