import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAXqXvJ7cS0C9jX164uYSFnb9G-xQEzURc",
  authDomain: "issac-playground-49934.firebaseapp.com",
  projectId: "issac-playground-49934",
  storageBucket: "issac-playground-49934.appspot.com",
  messagingSenderId: "28287899926",
  appId: "1:28287899926:web:e65e706b586d61e9585837",
  measurementId: "G-54XVW9SCL0",
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

const auth = firebase.auth();
const db = firebase.firestore();

export { db, auth };
export default firebase;
